import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  cacheControl,
  filterMarkets,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { getApi, markets } from '@hultafors/snickers/api';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
  SeoField,
} from '@hultafors/snickers/types';

import { createMeta } from '../util/create-meta';

const ContentArea = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.ContentArea),
);

const Page = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Page),
);

export interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

export const LandingPage: NextPage<LandingPageProps> = ({
  content,
  settings,
  hrefLanguages,
}) => {
  return (
    <>
      {content && (
        <Page
          metadata={createMeta(
            content?.seo as SeoField,
            settings?.language?.lang,
          )}
          testId="landing-page"
          hrefLanguages={hrefLanguages}
        >
          {content.contentArea && <ContentArea content={content.contentArea} />}
        </Page>
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { landingPage: content, ...globalFields },
    } = await dato.getLandingPage();

    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'SNICKERS'),
      content?.pageLocales,
    );

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        settings,
        ...(globalFields as GlobalFields),
        content,
        hrefLanguages,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export default LandingPage;
